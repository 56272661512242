<template>
    <div id="page-user-list">

        <vx-card class="mb-base">
            <vs-tabs v-if="loaded">
                <vs-tab :label="`Opened (${ticketData.opened.length})`" icon-pack="feather" icon="icon-unlock">
                    <div class="tab-text">
                        <vs-table stripe pagination max-items="10" search :data="ticketData.opened">

                            <template slot="header">
                                <div class="flex">
                                    <h3 class="mr-4">Opened Ticket</h3>
                                    <h3><vs-chip color="primary">{{ ticketData.opened.length }}</vs-chip></h3>
                                </div>
                            </template>

                            <template slot="thead">
                                <vs-th>Ticket Number</vs-th>
                                <vs-th>Date</vs-th>
                                <vs-th>Time</vs-th>
                                <vs-th>Deadline</vs-th>
                                <vs-th>Product</vs-th>
                                <vs-th>Customer</vs-th>
                                <vs-th>Priority</vs-th>
                            </template>

                            <template slot-scope="{data}">
                                <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                                    <vs-td :data="data[indextr].ticket_number">
                                        <a class="cursor-pointer" @click="detailTicket(data[indextr].complain_id)">{{ data[indextr].ticket_number }}</a>
                                    </vs-td>

                                    <vs-td :data="data[indextr].tanggal">
                                    {{ data[indextr].tanggal }}
                                    </vs-td>

                                    <vs-td :data="data[indextr].waktu">
                                    {{ data[indextr].waktu }}
                                    </vs-td>

                                    <vs-td :data="data[indextr].deadline">
                                    <vs-chip transparent color="danger">{{ data[indextr].deadline.substring(0, 10) }}</vs-chip>
                                    </vs-td>

                                    <vs-td :data="(data[indextr].product == null) ? '-' : data[indextr].product.name">
                                    {{ (data[indextr].product == null) ? '-' : data[indextr].product.name }}
                                    </vs-td>

                                    <vs-td :data="(data[indextr].company == null) ? '-' : data[indextr].company.company_name">
                                    {{ (data[indextr].company == null) ? '-' : data[indextr].company.company_name }}
                                    </vs-td>

                                    <vs-td :data="data[indextr].priority">
                                    {{ data[indextr].priority }}
                                    </vs-td>

                                </vs-tr>
                            </template>

                        </vs-table>
                    </div>
                </vs-tab>
                <vs-tab :label="`In Process (${ticketData.in_process.length})`" icon-pack="feather" icon="icon-refresh-cw">
                    <vs-table stripe pagination max-items="10" search :data="ticketData.in_process">

                        <template slot="header">
                            <div class="flex">
                                <h3 class="mr-4">In Process Ticket</h3>
                                <h3><vs-chip color="primary">{{ ticketData.in_process.length }}</vs-chip></h3>
                            </div>
                        </template>

                        <template slot="thead">
                            <vs-th>Ticket Number</vs-th>
                            <vs-th>Date</vs-th>
                            <vs-th>Time</vs-th>
                            <vs-th>Deadline</vs-th>
                            <vs-th>Product</vs-th>
                            <vs-th>Customer</vs-th>
                            <vs-th>Priority</vs-th>
                        </template>

                        <template slot-scope="{data}">
                            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                                <vs-td :data="data[indextr].ticket_number">
                                    <a class="cursor-pointer" @click="detailTicket(data[indextr].complain_id)">{{ data[indextr].ticket_number }}</a>
                                </vs-td>

                                <vs-td :data="data[indextr].tanggal">
                                {{ data[indextr].tanggal }}
                                </vs-td>

                                <vs-td :data="data[indextr].waktu">
                                {{ data[indextr].waktu }}
                                </vs-td>

                                <vs-td :data="data[indextr].deadline">
                                <vs-chip transparent color="danger">{{ data[indextr].deadline.substring(0, 10) }}</vs-chip>
                                </vs-td>

                                <vs-td :data="(data[indextr].product == null) ? '-' : data[indextr].product.name">
                                {{ (data[indextr].product == null) ? '-' : data[indextr].product.name }}
                                </vs-td>

                                <vs-td :data="(data[indextr].company == null) ? '-' : data[indextr].company.company_name">
                                {{ (data[indextr].company == null) ? '-' : data[indextr].company.company_name }}
                                </vs-td>

                                <vs-td :data="data[indextr].priority">
                                {{ data[indextr].priority }}
                                </vs-td>

                            </vs-tr>
                        </template>

                    </vs-table>
                </vs-tab>
                <vs-tab :label="`In Process Principal (${ticketData.in_process_principal.length})`"  icon-pack="feather" icon="icon-users">
                    <vs-table stripe pagination max-items="10" search :data="ticketData.in_process_principal">

                        <template slot="header">
                            <div class="flex">
                                <h3 class="mr-4">In Process Principal Ticket</h3>
                                <h3><vs-chip color="primary">{{ ticketData.in_process_principal.length }}</vs-chip></h3>
                            </div>
                        </template>

                        <template slot="thead">
                            <vs-th>Ticket Number</vs-th>
                            <vs-th>Date</vs-th>
                            <vs-th>Time</vs-th>
                            <vs-th>Deadline</vs-th>
                            <vs-th>Product</vs-th>
                            <vs-th>Customer</vs-th>
                            <vs-th>Priority</vs-th>
                        </template>

                        <template slot-scope="{data}">
                            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                                <vs-td :data="data[indextr].ticket_number">
                                    <a class="cursor-pointer" @click="detailTicket(data[indextr].complain_id)">{{ data[indextr].ticket_number }}</a>
                                </vs-td>

                                <vs-td :data="data[indextr].tanggal">
                                {{ data[indextr].tanggal }}
                                </vs-td>

                                <vs-td :data="data[indextr].waktu">
                                {{ data[indextr].waktu }}
                                </vs-td>

                                <vs-td :data="data[indextr].deadline">
                                <vs-chip transparent color="danger">{{ data[indextr].deadline.substring(0, 10) }}</vs-chip>
                                </vs-td>

                                <vs-td :data="(data[indextr].product == null) ? '-' : data[indextr].product.name">
                                {{ (data[indextr].product == null) ? '-' : data[indextr].product.name }}
                                </vs-td>

                                <vs-td :data="(data[indextr].company == null) ? '-' : data[indextr].company.company_name">
                                {{ (data[indextr].company == null) ? '-' : data[indextr].company.company_name }}
                                </vs-td>

                                <vs-td :data="data[indextr].priority">
                                {{ data[indextr].priority }}
                                </vs-td>

                            </vs-tr>
                        </template>

                    </vs-table>
                </vs-tab>
                <vs-tab :label="`In Process Customer (${ticketData.in_process_customer.length})`" icon-pack="feather" icon="icon-user">
                    <vs-table stripe pagination max-items="10" search :data="ticketData.in_process_customer">

                        <template slot="header">
                            <div class="flex">
                                <h3 class="mr-4">In Process Customer Ticket</h3>
                                <h3><vs-chip color="primary">{{ ticketData.in_process_customer.length }}</vs-chip></h3>
                            </div>
                        </template>

                        <template slot="thead">
                            <vs-th>Ticket Number</vs-th>
                            <vs-th>Date</vs-th>
                            <vs-th>Time</vs-th>
                            <vs-th>Deadline</vs-th>
                            <vs-th>Product</vs-th>
                            <vs-th>Customer</vs-th>
                            <vs-th>Priority</vs-th>
                        </template>

                        <template slot-scope="{data}">
                            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                                <vs-td :data="data[indextr].ticket_number">
                                    <a class="cursor-pointer" @click="detailTicket(data[indextr].complain_id)">{{ data[indextr].ticket_number }}</a>
                                </vs-td>

                                <vs-td :data="data[indextr].tanggal">
                                {{ data[indextr].tanggal }}
                                </vs-td>

                                <vs-td :data="data[indextr].waktu">
                                {{ data[indextr].waktu }}
                                </vs-td>

                                <vs-td :data="data[indextr].deadline">
                                    <vs-chip transparent color="danger">{{ data[indextr].deadline.substring(0, 10) }}</vs-chip>
                                </vs-td>

                                <vs-td :data="(data[indextr].product == null) ? '-' : data[indextr].product.name">
                                    {{ (data[indextr].product == null) ? '-' : data[indextr].product.name }}
                                </vs-td>

                                <vs-td :data="(data[indextr].company == null) ? '-' : data[indextr].company.company_name">
                                    {{ (data[indextr].company == null) ? '-' : data[indextr].company.company_name }}
                                </vs-td>

                                <vs-td :data="data[indextr].priority">
                                {{ data[indextr].priority }}
                                </vs-td>

                            </vs-tr>
                        </template>

                    </vs-table>
                </vs-tab>
                <vs-tab :label="`Closed (${ticketData.closed.length})`" icon-pack="feather" icon="icon-lock">
                    <vs-table stripe pagination max-items="10" search :data="ticketData.closed">

                        <template slot="header">
                            <div class="flex">
                                <h3 class="mr-4">Closed Ticket</h3>
                                <h3><vs-chip color="primary">{{ ticketData.closed.length }}</vs-chip></h3>
                            </div>
                        </template>

                        <template slot="thead">
                            <vs-th>Ticket Number</vs-th>
                            <vs-th>Date</vs-th>
                            <vs-th>Time</vs-th>
                            <vs-th>Deadline</vs-th>
                            <vs-th>Product</vs-th>
                            <vs-th>Customer</vs-th>
                            <vs-th>Priority</vs-th>
                        </template>

                        <template slot-scope="{data}">
                            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                                <vs-td :data="data[indextr].ticket_number">
                                    <a class="cursor-pointer" @click="detailTicket(data[indextr].complain_id)">{{ data[indextr].ticket_number }}</a>
                                </vs-td>

                                <vs-td :data="data[indextr].tanggal">
                                {{ data[indextr].tanggal }}
                                </vs-td>

                                <vs-td :data="data[indextr].waktu">
                                {{ data[indextr].waktu }}
                                </vs-td>

                                <vs-td :data="data[indextr].deadline">
                                <vs-chip transparent color="danger">{{ data[indextr].deadline.substring(0, 10) }}</vs-chip>
                                </vs-td>

                                <vs-td :data="(data[indextr].product == null) ? '-' : data[indextr].product.name">
                                {{ (data[indextr].product == null) ? '-' : data[indextr].product.name }}
                                </vs-td>

                                <vs-td :data="(data[indextr].company == null) ? '-' : data[indextr].company.company_name">
                                {{ (data[indextr].company == null) ? '-' : data[indextr].company.company_name }}
                                </vs-td>

                                <vs-td :data="data[indextr].priority">
                                {{ data[indextr].priority }}
                                </vs-td>

                            </vs-tr>
                        </template>

                    </vs-table>
                </vs-tab>
            </vs-tabs>
        </vx-card>
    </div>
</template>

<script>

export default {
    data() {
        return {
            loaded: false,
            ticketData: {
                opened : [],
                in_process : [],
                in_process_principal : [],
                in_process_customer : [],
                closed : []
            }
        }
    },
    methods : {
        detailTicket (id) {
            this.$router.push({name: 'trouble-ticket-detail', params: { id: this.$secure.encrypt(id) }});
        },
        getTicketData (sid, sname) {
            const self = this
            this.$store.dispatch('ticket/fetchTickets', {
                status: sname
            })
            .then(res => {
                const data = res.data.data

                switch (sid) {
                    case 0 :
                        self.ticketData.opened = data;
                        break;
                    case 1 :
                        self.ticketData.in_process = data;
                        break;
                    case 2 :
                        self.ticketData.in_process_principal = data;
                        break;
                    case 3 :
                        self.ticketData.in_process_customer = data;
                        break;
                    case 4 :
                        self.ticketData.closed = data;
                        break;
                    default :
                        self.ticketData.opened = data;
                }
            })
            .catch(err => {
                if (!err.response) {
                    // network error
                    err.response = {
                        data: {
                        message: 'Server error'
                        }
                    };
                }
                this.$vs.notify({
                title:'Opps something error',
                text: err.response.data.message,
                color:'danger'})
            })
            .finally(() => {
                if(sname == 'closed')
                    this.onLoaded()
            })
        },
        onLoaded(){
            this.loaded = true;
            this.$vs.loading.close()
        },
        loading () {
            this.loaded = false;

            this.$vs.loading({
                type: 'border',
                color: 'primary',
                text: `Wait a minute, It's getting data.`
            })
        },
    },
    created() {
        this.loading()
        this.getTicketData(0, 'opened')
        this.getTicketData(1, 'in process')
        this.getTicketData(2, 'in process principal')
        this.getTicketData(3, 'in process customer')
        this.getTicketData(4, 'closed')
    }
}
</script>

<style>
    .vs-tabs--li {
        border: 1px solid #EDEDED;
        padding: 0 10px;
    }

    .activeChild {
        border: 1px solid rgba(var(--vs-primary), 0.4) !important;;
    }
</style>
